// components/ContactForm/ContactForm.js
import React, { useState } from "react";
import { 
	Box, 
	Heading, 
	FormControl, 
	FormLabel, 
	Input, 
	Textarea, 
	Button, 
	Container,
	Text,
	Flex,
	useToast, 
	FormErrorMessage
} from "@chakra-ui/react";

import useSendContactEmail from "hooks/Contact/useSendContactFormEmail";

import { sanitizeInput, isValidEmail } from "utils/utils";

import { motion } from "framer-motion";

import backgroundImage from "../../../assets/ContactFormBackground.webp";

const MotionBox = motion(Box); 

const ContactForm = () => {

	const [loading, setLoading] = useState(false);

	const [input, setInput] = useState({
		name: "",
		email: "",
		message: "",
	});

	const [fieldErrors, setFieldErrors] = useState({
		name: null,
		email: null,
		message: null,
	});

	const handleUpdateInput = (e) => {
		setInput({
			...input,
			[e.target.name]: sanitizeInput(e.target.value),
		});
	};

	const validateInput = () => {
		let valid = true;
		const newFieldErrors = {
			name: null,
			email: null,
			message: null,
		};

		if (!input.name) {
			newFieldErrors.name = "Please enter your name.";
			valid = false;
		}

		if (!input.email) {
			newFieldErrors.email = "Please enter your email address.";
			valid = false;
		}
		if(!isValidEmail(input.email)){
			newFieldErrors.email = "Please enter a valid email address.";
			valid = false;
		}

		if (!input.message) {
			newFieldErrors.message = "Please enter a message.";
			valid = false;
		}

		setFieldErrors(newFieldErrors);
		return valid;
	};

	const { sendEmail } = useSendContactEmail();
	const toast = useToast();

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!validateInput()) return;

		setLoading(true);

		const { error } = await sendEmail({
			name: input.name,
			email: input.email,
			message: input.message,
		});

		if(error){
			toast({
				title: "Error",
				description: error.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			setLoading(false);
			return;
		} else {
			toast({
				title: "Success",
				description: "Your message has been sent!",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			setLoading(false);
			setInput({
				name: "",
				email: "",
				message: "",
			});
			return;
		}
	}; 

	return (
		<MotionBox
			h={{
				base: "auto",
				md: "100%",
			}}
			w="100%"
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 1, delay: 0.5 }}
			backgroundImage={`url(${backgroundImage})`}
			backgroundPosition="center 80%"
			backgroundRepeat="no-repeat"
			backgroundSize="fit"
		>
			<Container
				py={{
					base: "16",
					md: "16",
				}}
				px={{
					base: "auto",
					md: "10",
				}}
				maxW="container.xl"
				mb={{
					base: "10",
					md: "4rem",
				}}
				mt={{
					base: "10",
					md: "4rem",
				}}
				w={{
					base: "auto",
					md: "100%",
				}}
				bg="whiteAlpha.900"
				borderWidth="1px"
				borderColor="gray.200"
				borderRadius="lg"
				boxShadow="md"
			>
				<Flex
					justifyContent="space-between"
					alignItems="center"
					flexDirection={{ base: "column", md: "row" }}
					spacing="10"
				>
					<Flex
						flexDirection="column"
						alignItems="center"
						mb={{ base: "3", md: "3" }}
						mt={{ base: "2", md: "0" }}
						mx="auto"
					>
						<Heading as="h2" variant="black_bold" textAlign="center" mb={{ base: "5", md: "4" }} w={{ md: "100%" }}>
								Need to get in touch?
						</Heading>
						<Text
							variant="black_text"
							textAlign="center"
							mb={{ base: "4", md: "0" }}
							w={{ base: "98%", md: "80%" }}
						>
								Fill out the form and a team member will reach out to you.
						</Text>
					</Flex>
					<form 
						onSubmit={handleSubmit} 
						width={{ 
							base: "100%",
							md: "55%"
						 }}
					>
						<Box 
							maxWidth="100%"
							mx="auto"
							w={{
								base: "65vw",
								md: "30vw",
							}}
						>
							<FormControl 
								id="name" 
								mb="4"
								isRequired
								isInvalid={fieldErrors.name}	
							>
								<FormLabel>Name</FormLabel>
								<Input 
									type="text"
									name="name"
									value={input.name}
									onChange={handleUpdateInput}
									variant="primary"
								/>
								<FormErrorMessage>{fieldErrors.name}</FormErrorMessage>
							</FormControl>
							<FormControl 
								id="email2" 
								mb="4"
								isRequired
								isInvalid={fieldErrors.email}
							>
								<FormLabel>Email address</FormLabel>
								<Input 
									type="email"
									name="email"
									value={input.email}
									onChange={handleUpdateInput}
									variant="primary"
								/>
								<FormErrorMessage>{fieldErrors.email}</FormErrorMessage>
							</FormControl>
							<FormControl 
								id="message" 
								mb="4"
								isRequired
								isInvalid={fieldErrors.message}
							>
								<FormLabel>Message</FormLabel>
								<Textarea 
									borderColor="gray.300"
									borderWidth="1"
									borderRadius="lg"
									boxShadow="md"
									bg="white"
									_hover={{
										transform: "translateY(-1px)",
										borderColor: "gray.400",
										cursor: "pointer",
									}}
									name="message"
									value={input.message}
									onChange={handleUpdateInput}
								/>
								<FormErrorMessage>{fieldErrors.message}</FormErrorMessage>
							</FormControl>
							<Flex
								justifyContent="center"
								alignItems="center"
								w="100%"
							>
								<Button 
									type="submit" 
									variant="primary"
									isLoading={loading}
									loadingText="Submitting"
									w="50%"
								>
									Submit
								</Button>
							</Flex>
						</Box>
					</form>
				</Flex>
			</Container>
		</MotionBox>
	);
};

export default ContactForm;


