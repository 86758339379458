import React from "react";
import { 
	Box, 
	Heading, 
	Text, 
	Container, 
	Stack,  
	SimpleGrid,
	List,
	ListItem,
	ListIcon,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

import { MdCheckCircle } from "react-icons/md";

const MotionBox = motion(Box);
const MotionHeading = motion(Heading);

const About = () => {

	const data = [
		{
			title: "Restoration First",
			subtitle: "Looking for a part to restore your car?",
			bullets: [
				"List the part you want to buy",
				"Just provide the year, make, model, category, description, part number, and pictures",
				"Receive offers from vendors with the part you need",
				"All 100% FREE!"
			],
			icon: "https://via.placeholder.com/150",
		},
		{
			title: "Vendors",
			subtitle: "Have parts to sell?",
			bullets: [
				"Sell your spare restoration parts without listing them individually",
				"Browse the buyer's listings and turn your spare parts into cash",
				"Just provide the year, make, model, and category of the parts you have and get notified when a buyer is looking for one of your parts",
				"Or post your inventory and let the buyers come to you",
			],
			icon: "https://via.placeholder.com/150",
		},
		{
			title: "Alerts",
			subtitle: "Real-Time notifications straight to your inbox",
			bullets: [
				"Sell spare parts you have on hand or request exactly the part you're looking for",
				"Start as general as the make and model of the vehicle or as specific as the category and year",
				"Receive notifications immediately every time someone is looking for a part you have or when a vendor has the part you need",
			],
			icon: "https://via.placeholder.com/150",
		},
	];

	return (
		<MotionBox
			h={{
				base: "auto",
				md: "100%",
			}}
			mt={{
				base: "1rem",
				md: "0rem"
			}}
			mb={{
				base: "2rem",
				md: "1.45rem"
			}}
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 1, delay: 0.5 }}
		>
			<Container
				py={{
					base: "8",
					md: "18",
				}}
				maxW="container.2xl"
				align="center"
				justify="center"
			>
				<Stack
					spacing={{
						base: "4",
						md: "6",
					}}
					textAlign="center"
					px={{
						base: "4",
						md: "10",
					}}
					py="14"
				>
					<MotionHeading
						as="h2"
						variant="black_bold"
						mt={{
							base: "6"
						}}
						initial={{ opacity: 0, y: 50 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 1, delay: 1 }}
					>
						What Makes us Different
					</MotionHeading>
					<Text
						variant="black_text"
						mb={{
							base: "1rem",
							md: "1.75rem",
						}}
					>
						Built with automotive restoration at the core of our platform, 
						we are the first to offer a comprehensive solution for the automotive restoration community.
						<br />
						Connecting buyers and sellers of restoration parts like never before.
					</Text>
					<SimpleGrid
						columns={{
							base: 1,
							md: 3,
						}}
						spacingX={{
							base: "8",
							md: "3",
						}}
						spacingY={{
							base: "8",
							md: "auto",
						}}
					>
						{data.map((feature, idx) => (
							<Stack
								key={"feature-" + idx}
								spacing={{
									base: "2",
									md: "5",
								}}
								alignItems="center"
								justify="flex-start"
								boxShadow="lg"
								borderWidth="1px"
								borderRadius="lg"
								borderColor="gray.400"
								py={{
									base: "2",
									md: "6",
								}}
								px={{
									base: "4",
									md: "0",
								}}
							>
								<Heading
									as="h3"
									variant="black_bold"
								>
									{feature.title}
								</Heading>
								<Heading
									as="h4"
									variant="accent_small"
									fontSize={{
										base: "xl",
									}}
									mb={{
										base: "1rem",
										md: "0.75rem",
									}}
								>
									{feature.subtitle}
								</Heading>
								<List
									w="80%"
									justifyContent={"center"}
									alignItems="center"
									spacing={{
										base: "6",
										md: "4",
									}}
									py={{
										base: "2",
									}}
								>
									{feature.bullets.map((bullet, idx) => (
										<ListItem
											key={"bullet-" + idx}
											fontSize={{
												base: "xl",
												md: "xl",
											}}
											variant="black_text"
											textAlign={"left"}
										>
											<ListIcon as={MdCheckCircle} color="primary" />
											{bullet}
										</ListItem>
									))}
								</List>
							</Stack>
						))}
					</SimpleGrid>
				</Stack>
			</Container>
		</MotionBox>
	);
};

export default About;
