import { defineStyle } from "@chakra-ui/react";

import { colors } from "../../theme/colors";

export const mpcHeading = defineStyle({
	variants: {
		primary_small: {
			color: colors.primary,
			fontWeight: "semi-bold",
			fontSize: "xl",
		},
		black: {
			color: colors.black,
			fontSize: "5xl",
		},
		black_bold: {
			color: colors.black,
			fontWeight: "bold",
			fontSize: "5xl",
		},
		black_medium: {
			color: colors.black,
			fontWeight: "normal",
			fontSize: "3xl",
		},
		follow: {
			color: colors.black,
			fontWeight: "bold",
			fontSize: "2xl",
			transition: "all 0.2s ease-in-out",
			_hover: {
				cursor: "pointer",
				transform: "scale(1.05)",
			},
		},
		accent_small: {
			color: colors.accent,
			fontWeight: "semi-bold",
			fontSize: "xl",
		},
	},
});
