/**
 * @file Logo.js is the logo component for the application
 * @note This component is used in the Header component
 */

import React from "react";
import logo from "../assets/MPC_Tagline_Logo.png";

import {
	Image
} from "@chakra-ui/react";

/**
 * Logo component for the application
 *
 * @returns {React.Component} The logo component for the application
 */
const Logo = () => {
	return (
		<Image
			src={logo}
			fallbackSrc="https://via.placeholder.com/150"
			alt="Logo"
			fit="contain"
			loading="lazy"
			w={{
				base: "auto",
				lg: "50vw"
			}}
			h={{
				base: "auto",
				lg: "6vh"
			}}
			minW={{
				base: "auto",
				lg: "490px"
			}}
			minH={{
				base: "auto",
				lg: "280px"
			}}
			maxW={{
				base: "auto",
				lg: "500px"
			}}
			maxH={{
				base: "auto",
				lg: "390px"
			}}
			mb={{
				base: "auto",
				lg: "3vh"
			}}
		/>
	);
};

export default Logo;
