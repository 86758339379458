import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "styles";
import "@fontsource/lato";

import { ApolloProvider, ApolloClient, InMemoryCache} from "@apollo/client";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const cache = new InMemoryCache({
	dataIdFromObject: object => `${object.__typename}:${object._id}`,
});

// eslint-disable-next-line no-undef
const BACKEND_LOCATION = process.env.REACT_APP_BACKEND_LOCATION || "https://localhost:4000/graphql";
const client = new ApolloClient({
	uri: BACKEND_LOCATION,
	credentials: "include",
	cache: cache,
});

const router = createBrowserRouter([
	{ 
		path: "/*", 
		element: <Root />,
	},
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<ChakraProvider theme={theme}>
			<ApolloProvider client={client}>
				<RouterProvider 
					router={router}
					fallbackElement={<div>Loading...</div>}  
				/>
			</ApolloProvider>
		</ChakraProvider>
	</React.StrictMode>
);

function Root() {
	return (
		<App />
	);
}
