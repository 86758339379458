import { defineStyle } from "@chakra-ui/react";

import { colors } from "../../theme/colors";

export const mpcText = defineStyle({
	variants: {
		black_text: {
			color: colors.black,
			fontSize: "xl",
		},
		input_error_text: {
			color: colors.error,
			fontSize: "sm",
		},
		error_text: {
			color: colors.error,
			fontSize: "lg",
			fontWeight: "bold",
		},
		success_text: {
			color: colors.success,
			fontSize: "lg",
			fontWeight: "bold",
		},
		dark_bold: {
			color: colors.dark,
			fontWeight: "semi-bold",
			fontSize: "2rem",
		},
		agreement: {
			color: colors.black,
			fontSize: "lg",
			fontWeight: "normal",
		},
	},
});
