import { extendTheme, keyframes } from "@chakra-ui/react";

import { colors, fonts, config } from "./theme";

import { components } from "./components";

const rotateAnimation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const styles = {
	global: {
		"html, body, *": {
			"::-webkit-scrollbar": {
				width: "9px", // you can adjust this to change the thickness of the scrollbar
				position: "fixed", // add this line to fix the scrollbar position
				right: "0", // add this line to align the scrollbar to the right
			},

			"::-webkit-scrollbar-track": {
				background: "transparent", // this makes the background of the scrollbar track transparent
			},

			"::-webkit-scrollbar-thumb": {
				background: "gray.400", //"primary.300", // this is the color of the scroll thumb
				borderRadius: "8px", // this makes the scroll thumb round
			},

			"::-webkit-scrollbar-thumb:hover": {
				cursor: "pointer",
				background: "primary_white", // this changes the color of the scroll thumb when hovering
				border: `1px solid ${colors.primary}`, // this adds a border to the scroll thumb
				borderRadius: "8px", // this makes the scroll thumb round
			},
			".custom-scrollbar::-webkit-scrollbar": {
				width: "0px",
			},
			".custom-scrollbar::-webkit-scrollbar-thumb": {
				background: "transparent",
			},
			".custom-scrollbar::-webkit-scrollbar-track": {
				background: "transparent",
			},
			".custom-scrollbar": {
				scrollbarWidth: "thin",
				scrollbarColor: "transparent transparent",
			},
			".loading-spinner": {
				display: "flex",
				height: "100%",
				width: "100%",
				minHeight: "50vh",
				justifyContent: "center",
				alignItems: "center",
			},
			".loader": {
				width: "60px",
				height: "60px",
				borderRadius: "50%",
				display: "inline-block",
				borderTop: "4px solid #fff",
				borderRight: "4px solid transparent",
				boxSizing: "border-box",
				animation: `${rotateAnimation} 1s linear infinite`,
				_after: {
					content: "''",
					boxSizing: "border-box",
					position: "absolute",
					left: 0,
					top: 0,
					width: "60px",
					height: "60px",
					borderRadius: "50%",
					borderBottom: `4px solid ${colors.primary}`,
					borderLeft: "4px solid transparent",
				},
			},
			".footer": {
				display: "flex",
				width: "100%",
				backgroundColor: colors.light,
			},
		},
		"body, #root": {
			display: "inline",
			margin: 0,
			minHeight: "100vh",
			overflowY: "scroll",
			textAlign: "center",
		},
		body: {
			height: "100%",
			width: "100%",
			margin: 0,
			"--webkit-font-smoothing": "antialiased",
			"--moz-osx-font-smoothing": "grayscale",
		},
		option: {
			bg: "white",
			color: "black",
		},
	},
};

const theme = extendTheme({
	components,
	fonts,
	colors,
	config,
	styles: styles,
});

export { theme };
