/**
 * @TODO
 * - Request, Sell, Alerts/Notifications
 * - 'What makes us different?'
 */
import { GiHomeGarage } from "react-icons/gi";
import { FaUsers } from "react-icons/fa";
import { AiOutlineFileSearch } from "react-icons/ai";
//We bring buyers and sellers together 
export const features = [
	{
		name: "Marketplace",
		description: "Connect with users, shop for parts, and more. ",
		modalDescription:
      	"Connect with fellow enthusiasts and share your passion for car restoration. Gain followers, save posts, leave comments, and so much more. ",
		icon: FaUsers,
		color: "primary_gradient.500",
	},
	{
		name: "Virtual Garage",
		description: "Organize your builds, parts, and more in your virtual garage.",
		modalDescription:
      	"Add your projects to your virtual garage and keep track of your builds, parts, and more.",
		icon: GiHomeGarage,
		color: "primary_gradient.500",
	},
	{
		name: "Search Engine",
		description: "Reliably matching the parts you need with the vendors that have them.",
		modalDescription:
        "With sophisticated algorithms, our advanced search engine provides accuracy and reliability, connecting users to the parts they need and vendors to the customers they want.",
		icon: AiOutlineFileSearch,
		color: "primary_gradient.500",
	},
];

