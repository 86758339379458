import { mpcText, mpcHeading, mpcButton } from "./simple";

import {
	mpcInput,
} from "./complex";

export const components = {
	Heading: mpcHeading,
	Text: mpcText,
	Button: mpcButton,
	Input: mpcInput,
};
