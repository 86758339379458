import { defineStyle } from "@chakra-ui/react";

import { colors } from "../../theme/colors";

export const mpcButton = defineStyle({
	baseStyle: {
		transition: "all 0.2s ease-in-out",
		_hover: {
			transform: "scale(1.05)",
		},
	},
	variants: {
		primary: {
			background: colors.primary,
			color: colors.white,
			borderRadius: "50px",
			_hover: {
				background: colors.white,
				color: colors.primary,
				border: "1px solid " + colors.primary,
			},
		},
		inverse: {
			background: colors.accent,
			color: colors.white,
			borderRadius: "50px",
			_hover: {
				background: colors.white,
				color: colors.accent,
				border: "1px solid " + colors.accent,
			},
		},
		delete: {
			background: colors.error,
			color: colors.white,
			borderRadius: "50px",
			_hover: {
				background: colors.white,
				color: colors.error,
				border: "1px solid " + colors.error,
			},
		},
		deleteInverse: {
			background: colors.white,
			color: colors.error,
			border: "1px solid " + colors.error,
			borderRadius: "50px",
			_hover: {
				background: colors.error,
				color: colors.white,
				border: "1px solid " + colors.error,
			},
		},
		accept: {
			background: colors.success,
			color: colors.white,
			borderRadius: "50px",
			_hover: {
				background: colors.white,
				color: colors.success,
				border: "1px solid " + colors.success,
			},
		},
		nav: {
			background: colors.white,
			color: colors.black,
			_hover: {
				transform: "translateY(-2px)",
				color: "primary",
			},
		},
		outline: {
			background: colors.white,
			color: colors.primary,
			border: "1px solid " + colors.primary,
			borderColor: "gray.300",
			borderRadius: "50px",
			_hover: {
				background: colors.white,
				color: colors.primary,
				border: "1px solid " + colors.primary,
			},
		},
		form: {
			background: colors.white,
			color: colors.black,
			borderRadius: "20px",
			borderWidth: "1px",
			borderColor: colors.black,
			_hover: {
				background: colors.white,
				color: colors.primary,
				borderColor: colors.primary,
			},
		},
		plain: {
			color: colors.black,
			_hover: {
				color: colors.primary,
			},
		},
		gold: {
			background: colors.white,
			color: colors.gold[600],
			borderRadius: "50px",
			_hover: {
				background: colors.white,
				color: colors.gold[500],
				border: "1px solid " + colors.gold[500],
			},
		},
		transparent: {
			color: colors.white,
			borderRadius: "50px",
			border: "2px solid " + colors.primary,
			bg: "transparent",
		},
		link: {
			color: colors.accent,
			_hover: {
				color: colors.primary,
			},
		},
		linkLight: {
			color: colors.accent_gradient[300],
			_hover: {
				color: colors.primary,
			},
		},
		text: {
			color: colors.white,
			_hover: {
				color: colors.primary,
			},
		},
		textDark: {
			color: colors.black,
			_hover: {
				color: "blackAlpha.700",
			},
		},
		pagination: {
			background: colors.white,
			color: colors.black,
			_hover: {
				background: colors.white,
				color: colors.primary,
				borderBottomColor: colors.primary,
				borderBottomWidth: "1px",
				borderBottomRadius: "0",
				transform: "none",
			},
		},
	},
	sizes: {
		xl: {
			fontSize: "xl",
			h: 14,
			minW: 14,
			px: 8,
		},
	},
});
