import { colors } from "./colors";

export const config = {
	initialColorMode: "light",
	useSystemColorMode: false,
	colorMode: {
		light: {
			color: colors.black,
			bg: colors.white,
			borderColor: colors.primary,
			placeholderColor: colors.primary,
			iconColor: colors.primary,
			textColor: colors.black,
			secondaryTextColor: colors.primary,
			headingColor: colors.black,
			subHeadingColor: colors.primary,
			buttonColor: colors.white,
			buttonBg: colors.primary,
			buttonHoverColor: colors.primary,
			buttonHoverBg: colors.white,
			buttonHoverBorderColor: colors.primary,
			buttonHoverBorder: "1px solid " + colors.primary,
		},
	},
};
