import React from "react";
import { 
	Box, 
	HStack,
	VStack, 
	Heading, 
	Icon, 
	List, 
	ListItem, 
	Stack, 
	Text
} from "@chakra-ui/react";
import { FiCheckCircle, FiMinus } from "react-icons/fi";

// Reusable PricingCard component
export const PricingCard = ({ title, price, features, colorScheme }) => {
    
	const bgGradient = colorScheme === "primary"
		? "linear(to-tr, primary_gradient.500, primary_gradient.700)"
		: "gray.50";
  
	const isPremium = title === "V12 Premium Membership";
	const textColor = isPremium ? "white" : "black";

	return (
		<Box
			bgGradient={bgGradient}
			borderWidth="1px"
			borderRadius="2xl"
			borderColor="gray.400"
			boxShadow="xl"
			px={{ base: "6", md: "8" }}
			py="10"
			width={{
				base: "100%",
				md: "full",
			}}
			maxW={{
				base: "md",
				md: "xl",
			}}
			mt={{
				base: "2",
				md: "4",
			}}
		>
			<Stack 
				spacing={{ base: "10", md: "10" }} 
				textAlign="center"
				justify="center"
			>
				{/* Pricing Card Title */}
				<Text 
					fontSize={{
						base: "2xl",
						md: "3xl"
					}}
					fontWeight="semibold" 
					color={textColor}
				>
					{title}
				</Text>

				{/* Pricing Details */}
				<HStack 
					spacing="4"
					align="center"
					justify="center"
					px={4}
				>
					{isPremium ? (
						<>
							<Heading
								as="s"
								variant="black_medium"
								fontWeight="bold"
								fontSize={{
									base: "5xl",
									md: "3xl"
								}}
								color="white"
								textDecoration="line-through"  // <-- Striked through
							>
								{price}
							</Heading>
							<Text 
								variant="black_text"
								mt={2}
								color="white"
								fontSize={{
									base: "xl"
								}}
							>
      							FREE
							</Text>
						</>
					) : (
						<Heading 
							as="s" 
							variant="primary_small"
							fontWeight="bold"
							fontSize={{
								base: "5xl",
								md: "3xl"
							}}
							textDecoration="none"
							align="center"
							color="black"
						>
							{price}
						</Heading>
					)}
				</HStack>

				{/* Features List */}
				{features && (
					<List 
						spacing="4" 
						mx="auto" 
						align="center"
						w="100%"
					>
						{features.map((feature) => (
							<ListItem 
								key={feature} 
								color="fg.muted"
								w="100%"
							>
								<HStack 
									spacing="4"
									w="100%"
									alignItems="flex-start"
								>
									<Icon
										as={isPremium ? FiCheckCircle : FiMinus}  
										color={isPremium ? "white" : "gray.500"}
										boxSize="6"
									/>
									{
										feature === "3% Transaction fee" ? (
											<VStack>
												<Text 
													variant="black_text"
													textAlign="left"
													w="100%"
													as="s"
													color={textColor}
												>
													{feature}
												</Text>
												<Text
													variant="black_text"
													textAlign="left"
													w="100%"
													as="b"
													color={textColor}
												>
                                                    WAIVED FOR FREE TRIAL
												</Text>
											</VStack>
										) : (
											<Text 
												variant="black_text"
												textAlign="left"
												w="100%"
												color={textColor}
											>
												{feature}
											</Text>
										)
									}
								</HStack>
							</ListItem>
						))}
					</List>
				)}
			</Stack>
		</Box>
	);
};

// Our Main Component that will render the multiple cards
const PricingCardsContainer = () => {

	const tiers = [
		{
			title: "V6 Basic Membership",
			price: "$0/mo",
			features: ["Unlimited request listings", "5 Credits / Mo.", "5% Transaction fee"],
			colorScheme: "gray",  // <-- Gray gradient
		},
		{
			title: "V12 Premium Membership",
			price: "$12/mo",
			features: ["Unlimited request listings", "Two car garage", "15 Selling Posts", "15 Credits / Mo.", "3% Transaction fee"],
			colorScheme: "primary",  // <-- Orange gradient
		},
		{
			title: "V8 Pro Membership",
			price: "$8/mo",
			features: ["Unlimited request listings", "One car garage", "10 Credits / Mo.", "4% Transaction fee"],
			colorScheme: "gray",  // <-- Gray gradient
		},
	];
  
	return (
		<HStack 
			spacing="8" 
			flexDirection={{ base: "column", md: "row" }}  // <-- changes to column on mobile/small screens
			align="center"
			justify="center"
			width="100%"
		>
			{tiers.map((tier, index) => (
          		<PricingCard key={index} {...tier} />
			))}
		</HStack>
	);
};
  
export default PricingCardsContainer;