import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(inputAnatomy.keys);

const mpcFlushed = definePartsStyle({
	field: {
		borderBottomColor: "gray.400",
		borderBottomWidth: "1px",
		borderBottomRadius: "0",
		_hover: {
			borderColor: "gray.400",
		},
		_focus: {
			borderColor: "gray.400",
		},
		_active: {
			borderColor: "gray.400",
		},
	},
});

export const inputTheme = defineMultiStyleConfig({
	variants: {
		flushed: {},
		mpcFlushed,
		primary: {
			field: {
				borderRadius: "lg",
				borderColor: "gray.300",
				borderWidth: "1px",
				bg: "white",
				_hover: {
					transform: "translateY(-2px)",
					borderColor: "gray.400",
					cursor: "pointer",
				},
			},
		},
	},
});
