/**
 * @constants MPC Colors
 **/
export const colors = {
	// Base Colors
	primary: "#D72323",
	accent: "#33425B",
	light: "#E2E0E0",
	dark: "#33425B",
	white: "#FFFFFF",
	black: "#000000",
	success: "#227E3F",
	error: "#B03049",
	link: "#006AFF",
	sell: "#b91c1c",
	request: "#36BF00",

	// Gradients
	primary_gradient: {
		50: "#fef2f2",
		100: "#fee2e2",
		200: "#fecaca",
		300: "#fca5a5",
		400: "#f87171",
		500: "#ef4444",
		600: "#d72323",
		700: "#b91c1c",
		800: "#991b1b",
		900: "#7f1d1d",
		950: "#450a0a",
	},
	gold: {
		50: "#faf9ec",
		100: "#f4f0cd",
		200: "#ebdf9d",
		300: "#dfc865",
		400: "#d4af37",
		500: "#c59b2d",
		600: "#aa7a24",
		700: "#885920",
		800: "#724921",
		900: "#623e21",
		950: "#382010",
	},
	accent_gradient: {
		50: "#f5f7fa",
		100: "#eaedf4",
		200: "#d1d9e6",
		300: "#a9b9d0",
		400: "#7a93b6",
		500: "#59759e",
		600: "#465d83",
		700: "#394b6b",
		800: "#33425b",
		900: "#2e394c",
		950: "#1e2533",
	},
	membershipGradient: {
		v6: "linear(to-bl, #FFFFFF, #FEFFFF, #FCFEFF, #F1F4FF, #F1F4FF)",
		v8: "linear(to-b, #ef4444, #d72323, #b91c1c)",
		v12: "linear(to-b, #c59b2d, #aa7a24, #885920)",
	},
};
